import React from "react";
import cilt from "../servicesphotos/cilt-prob.jpeg";
import cilt2 from "../servicesphotos/cilt-prob2.jpeg";
import cilt3 from "../servicesphotos/ciltprob3.jpeg";
import cilt4 from "../servicesphotos/ciltprob4.jpeg";
const LekeTedavileri = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={cilt} alt="Cilt Problemleri" />
        <p className="text-gray-900 w-full">
          Güneş, yaşlılık, hamilelik, hormon tedavisi ve doğum kontrol hapları
          gibi sebeplerle cilde rengini veren melaninin daha fazla üretilmesiyle
          cilt lekeleri oluşur. Kahverengi yama şeklinde de alın, dudak üstü,
          yanaklarda gördüğümüz cilt lekelerini melazma diye adlandırırız.
          Hamilelik döneminde görülebilen bu lekeler kloazma olarak
          adlandırılır. Bazen yaşın ilerlemesi ve güneş ışınlarının etkisiyle
          lentigo denen bölgesel lekelenmeler oluşur. Akne izi ve sivilce
          problemi de çok sayıda kişinin ortak sorunlarından biridir. Özellikle
          yüz bölgesinde meydana gelen akne izlerinin tedavisi oldukça hızlı bir
          şekilde yapılabiliyor.
        </p>
        <p className="text-gray-900 w-full">
          Kişinin cilt yapısı ve oluşan problemin durumuna bağlı olarak kişiye
          uygulanan yöntemler değişebilir. Kimyasal peeling,Enzim Peeling, PRP,
          mezoterapi tedavisi güzel sonuçlar veren uygulamalardır.Akne sivilce
          leke gibi cilt problemleri tedavisinde Fraksiyonel lazerler, başarılı
          ve etkin bir şekilde kullanılmaktadır.
        </p>
        <img src={cilt2} alt="Cilt Problemleri" />
        <p className="text-gray-900 w-full">
          Çoğunlukla leke tedavilerinde tek bir yöntem yeterli olmaz, bu nedenle
          kombine şekilde kullanılabilir. Bu yöntemler ayrıca leke tedavisi
          güneş kremleri ve antioksidan takviyelerle de desteklenebilir.
        </p>
        <p className="text-gray-900 w-full">
          Mezoterapi; leke de açıcı ve antioksidan etkili maddeler ince
          iğnelerle deri altına enjekte edilerek uygulanan tedavi
          yöntemidir.Akne sivilce izi tedavisinde Mezoterapi ise kollajen
          yapımını uyarır. Cildin üst tabakalarının onarılmasını sağlayarak
          izlerin ortadan kalkmasına da olanak tanır.
        </p>
        <img src={cilt3} alt="Cilt Problemleri" />
        <p className="text-gray-900 w-full">
          PRP ile Leke ve Cilt problemleri Tedavisi ;Hastanın kendi kanıyla
          hazırlanan kan dolaşımı ile taşınan trombositten zengin plazma
          kısmının, tedavi edilecek bölgeye mikroenjeksiyon yoluyla enjekte
          edilmesiyle yapılan tedavidir. Trombositler, büyüme faktörlerini
          yapısında bulunduran kan elemanlarıdır. Kollajen yapımını uyarır.PRP
          bu büyüme faktörleri aracılığı ile cildi yeniler ve yenilenen cilt
          aydınlanır, canlanır.
        </p>
        <img src={cilt4} alt="Cilt Problemleri" />
        <p className="text-gray-900 w-full">
          Kimyasal Peeling;Meyve asitleri ile cildin üst ölü tabakasının
          soyulması işlemidir. Cildin üst katmanını hafifçe soyarak pigment
          üreten hücrelerin pigment sentezini baskılar. Aynı zamanda kolajen
          sentezini artırır, bu da hücrelerin yenilenmesini tetikler. Yüzeyel
          lekelerde daha etkilidir.
        </p>
      </div>
    </div>
  );
};

export default LekeTedavileri;
