import React from "react";
import sacprp from "../servicesphotos/sac-prp.jpeg"
const SacPRP = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={sacprp} alt="Saç PRP" />
        <p className="text-gray-900 w-full">
          PRP tedavisinin Saç dökülmesi tedavisinde önemli bir yeri vardır.
          Kişiden alınan kan özel bir tüpe konulur ve santrifüj edilir. Bu
          belirli işlemden geçirildikten sonra büyüme faktörlerinden zengin
          serum bölümü ortaya çıkar. Ardından bu özel ve yenileyici sıvı,
          kişinin saçlı derisine ince küçük iğneler yardımı ile uygulanır. Bu
          büyüme faktörleri, saç köklerini uyarıp, saç dökülmesini
          durdurabilmekte, yeni saç çıkışını sağlayabilmektedir.
        </p>
      </div>
    </div>
  );
};

export default SacPRP;
