import React from "react";
import hydrafacial from "../servicesphotos/hydrafacial.jpeg";
import hydrafacial2 from "../servicesphotos/hydrafacial2.jpeg";
const Hydrafacial = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={hydrafacial} alt="Hydrafacial" />
        <p className="text-gray-900 w-full">
          Üç aşamalı bir cilt bakımı işlemi olan HydraFacial, cildinizi
          derinlemesine temizlemek, ölü hücrelerden arındırmak ve nemlendirmek
          için kullanılan bir uygulamadır. Bu medikal cilt bakımı sivilce,
          kuruluk ve kırışıklıklar gibi çeşitli cilt rahatsızlıklarının
          tedavisine de yardımcı olabilir. HydraFacial cilt bakımının genel cilt
          dokusunu, tonunu ve görünümünü iyileştirmeye yardımcı olur.
        </p>
        <img src={hydrafacial2} alt="Hydrafacial" />
        <p className="text-gray-900 w-full">
          HydraFacial'dan sonra, muhtemelen daha aydınlık bir ton, daha
          pürüzsüz, daha parlak bir cilt fark edeceksiniz.
        </p>
      </div>
    </div>
  );
};

export default Hydrafacial;
