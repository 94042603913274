import React from "react";

const GenclikAsisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <p className="text-gray-900 w-full">
          25 yaşından itibaren cildimizde kolajen kaybı başlamaktadır.Kolejen
          kaybıyla birlikte ciltte sarkma kırışıklık gibi yaşlanma etkileri
          başlar. Yaşla birlikte genetik faktörler, sigara kullanımı, güneş
          hasarı bu süreci hızlandırmaktadır.Gençlik ve kolejen aşılar cildin
          birkaç milim altına enjeksiyonuyla yaptığımız bir anti-aging (yaşlanma
          karşıtı) uygulamadır.Bu sayede ciltte kılcal mikro dolaşımı artırır ve
          cilde yumuşaklık, pürüzsüzlük ve genç bir görünüm kazandırmak için
          gerekli olan nem ve besinleri sağlar.Gençlik aşıları cilde yeniden
          canlılık, parlaklık, dayanıklılık ve gençlik ışıltısı kazandıracak
          güçlü bir antioksidandır.
        </p>
        <header className="text-gray-900 w-full font-bold">Kolajen aşısı en sık;</header>
        <p className="text-gray-900 w-full">
          Yüz, boyun ve dekolte bölgesinde elastikiyet kaybına bağlı oluşmuş
          kırışıklık ve sarkmaların giderilmesinde
        </p>
        <p className="text-gray-900 w-full">
          Akneye bağlı ciltte çukur şeklinde kalmış izlerin tedavisinde
        </p>
        <p className="text-gray-900 w-full">
          El üstündeki kırışıklıkların giderilmesinde uygulanmaktadır.
        </p>
        <header className="text-gray-900 w-full font-bold">Mezolifting Nedir? </header>
        <p className="text-gray-900 w-full">
          Mezolifting; deri içine vitamin, mineral ve cilt için gerekli olan
          bazı yapı elemanları enjekte ederek, derinin alt ve üst tabaka
          dokusunun yenilenmesidir. Yüz mezoterapisi olarak da adlandırılan
          mezolifting; cildi yeniler, kırışıklıkları ve cilt sarkmalarını
          önleyerek, cilde doğal görünümünü kazandırır.
        </p>
      </div>
    </div>
  );
};

export default GenclikAsisi;
