import React from "react";
import gidilipoliz from "../servicesphotos/gidi-lipoliz.jpeg";

const Lipoliz = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={gidilipoliz} alt="Gıdı Lipoliz" />

        <p className="text-gray-900  w-full">
          Gıdı; çenenin hemen altından başlayan orta hattaki ciltte gevşeme, yağ
          dokusunda genişleme, yer çekimi etkisiyle sarkma sonucu görülen
          estetik bir sorundur. Genç yaş grubunda da gıdı oluşu
          izlenebilmektedir. Burada en önemli etken kişinin kısa sürede fazla
          miktarda kilo alması veya vermesidir.
        </p>
        <p className="text-gray-900  w-full ">
          Gıdı bölgesindeki yağ dokusu deforme oldukça kişinin gıdı estetiği
          bozulur ve kişi olduğundan daha yaşlı görünür.
        </p>
        <p className="text-gray-900  w-full">
          Gıdı gençleştirmesinde başvurulan mezoterapi enjeksiyonları
          ameliyatsız ve etkili gıdı küçültme sağlayabilmektedir. Mezoterapi ile
          gıdıda biriken fazla yağ dokusunun azaltılması, gevşeyen cilt
          dokusunun sıkılaştırılarak kırışıklıkların azaltılması etkileri elde
          edilir. Aslında gıdı eritme bir lipoliz yani yağ eritme metodudur.
        </p>
        <p className="text-gray-900  w-full">
          Lipoliz yağ hücrelerinin küçültülmesi veya parçalanması anlamına
          gelmektedir. Lipoliz mezoterapisi sınırlı bölgelerde (lokal) aşırı yağ
          birikimi tedavisinde sık tercih edilen bir mezoterapi yöntemidir.
        </p>
      </div>
    </div>
  );
};

export default Lipoliz;
