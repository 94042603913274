import React from "react";
import ipaski from "../servicesphotos/ameliyatsiz-yüz-est.jpeg"
const IpAskiEstetigi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={ipaski} alt="İp Askı Estetiği" />
        <p className="text-gray-900 w-full">
          Ciltte askılama işlemi olan Fransız askı tekniği, biyolojik bakımdan
          insan vücuduna uyumlu iplerle gerçekleştiriliyor. Bu işlemin temel
          hedefi ise ciltte mevcut olan kırışıklıkları, sarkmaları ve gevşek
          cilt görünümünü ortadan kaldırmaktır.
        </p>
        <p className="text-gray-900 w-full">
          Bu iplerin dokulara herhangi bir zararı bulunmuyor. Özellikle daha
          genç görünmek isteyen ancak ameliyata sıcak bakmayanlar için idealdir.
          Cerrahi müdahale olmadan gençleşmek isteyenler için oldukça iyi sonuç
          veren bir uygulamadır. Kişinin cildinde meydana gelen kırışıklık
          oranına ya da sarkmaya bağlı olarak planlanabilen bir işlemdir. Kısa
          zamanda tamamlanması, istirahat gerektirmemesi de kısa sürede
          içerisinde gençleşme sağlayan yöntemdir.
        </p>
        <p className="text-gray-900 w-full">
          Orta derecede yüz sarkmalarında, kaşların düşmesinde, çene hattı
          bölgesindeki deformasyonlarda ve boyun ve gıdı toparlamada
          uygulanabilir.
        </p>
      </div>
    </div>
  );
};

export default IpAskiEstetigi;
