import React from "react";
import altinigne from "../servicesphotos/altin-igne.jpeg";
const AltinIgneTedavisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={altinigne} alt="Altın İğne Tedavisi" />
        <p className="text-gray-900 w-full">
          Altın iğne, fraksiyonel radyo frekans uygulaması olarak da
          adlandırılan bir cilt gençleştirme ve güzelleştirme uygulamasıdır.
          Yaşlanma ve çevresel faktörlerden kaynaklı olarak ciltte oluşan
          deformasyonlar, lekelenmeler ve kırışıklıkların görünümünü azaltmaya,
          cildi daha canlı, parlak ve genç göstermeye yardımcı olur. Seanslar
          şeklinde yapılan altın iğne uygulamasında seans sayısına işlemi
          uygulayan hekim tarafından cildin durumuna ve çalışılacak bölgeye
          bakılarak karar verilir. Altın iğne uygulaması, hem yüzde hem de
          vücudun farklı bölgelerinde güvenle uygulanabilir. İşlem için
          kullanılan cihaz cildin yüzeyini etkilemezken alt katmanlara yüksek
          frekansta enerji gönderir. Cildin alt tabakalarına kontrollü olarak
          hasar verilirken vücuttaki iyileşme süreçleri ile doğal kolajeni
          uyarır. Bu sayede lekeler, kırışıklıklar ve yara izleri iyileştirilir,
          cildin kendini yenilemesi desteklenir. İşlemde altın iğnelerin
          kullanılmasının nedeni ise enerjinin dağılmasını önleyerek cilt
          yüzeyinin zarar görmesini engellemek, aynı zamanda enerji kaybını
          azaltmaktır.
        </p>
        <p className="text-gray-900 w-full">
          Altın iğne, birçok cilt kusurunu tedavi edebilme özelliğine sahip olan
          bir cilt yenileme yöntemidir. Bu nedenle kullanım alanı oldukça
          geniştir. Bunlardan bazıları şu şekildedir:
        </p>
        <p className="text-gray-900 w-full">
          Yanık ve yara izlerinin tedavileri,
        </p>
        <p className="text-gray-900 w-full">
          Kilo alıp vermeye veya hamileliğe bağlı olarak oluşan çatlakların
          giderilmesi,
        </p>
        <p className="text-gray-900 w-full">
          Yaşlanma belirtilerinin giderilmesi,
        </p>
        <p className="text-gray-900 w-full">
          İnce kırışıklıkların yok edilmesi,
        </p>
        <p className="text-gray-900 w-full">
          Boyun, gıdı ve dekolte bölgelerinin görünümünün düzeltilmesi,
        </p>
        <p className="text-gray-900 w-full">Akne ve izlerinin tedavisi,</p>
        <p className="text-gray-900 w-full">Gözeneklerin sıkılaştırılması,</p>
        <p className="text-gray-900 w-full">
          Vücutta form kaybı ve sarkıklıkların söz konusu olduğu bölgelerin
          düzeltilmesi,
        </p>
        <p className="text-gray-900 w-full">
          Cilt lekelerinin giderilmesi gibi alanlarda altın iğne uygulamasından
          yararlanılabilir.
        </p>
      </div>
    </div>
  );
};

export default AltinIgneTedavisi;
