import React from "react";
import masseter from "../servicesphotos/masseterbotoks.jpg"
const MasseterBotox = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img className="h-96 " src={masseter} alt="Masseter Botox" />
        <p className="text-gray-900 w-full">
          Masetter (çiğneme kası) Hipertrofisi: Diğer bölge uygulamalarından
          farklı olarak massetter kasına Botox uygulamaları kırışıklıklar için
          değil, özellikle diş sıkma probleminde massetter botox diş sıkma
          problemini ortadan kaldırır ve hastaların sahip olduğu kare yüz
          görünümünü yumuşatmak ve oval bir yüz elde etmek için yapılır. Böylece
          daha ince ve feminen bir yüz görünümü elde edilmiş olunur.
        </p>
      </div>
    </div>
  );
};

export default MasseterBotox;
