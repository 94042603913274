/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
const Contact = () => {
  return (
    <div className="flex flex-col my-12 mx-6 justify-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3117.54241482782!2d27.4122298151735!3d38.613401079615365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b99d8b370f6bb1%3A0x566748b5f8520f5!2zRHIuVMO8bGF5IFnEsWxkxLFyxLFt!5e0!3m2!1str!2str!4v1661453241548!5m2!1str!2str"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        className="lg:pr-12 md:pr-4 "
      ></iframe>
      <div className="flex flex-row text-center my-6  space-x-4">
        <FaMapMarkerAlt className="text-blue-700 w-6 h-6" />
        <text className="font-medium text-xs md:text-sm sm:text-xs lg:text-base">
          Tevfikiye Mahallesi 3813 Sokak Öğretmenler Apartmanı No:31 İçkapı
          No:2, 45120 Yunusemre/Manisa
        </text>
      </div>
      <div className="flex flex-row text-center space-x-4">
        <FaPhoneAlt className="text-red-700 w-6 h-6 " />
        <text className="font-medium text-xs md:text-sm sm:text-xs lg:text-base">
          0530 429 23 06
        </text>
      </div>
    </div>
  );
};

export default Contact;
