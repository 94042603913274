import React from "react";
import antiaging from "../servicesphotos/anti-aging.jpeg"
const Mezoterapi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img  src={antiaging} alt="Mezoterapi" />
        <p className="text-gray-900 w-full">
          Mezoterapi cilt yenileme, anti-aging, bölgesel zayıflama, selülit,
          çatlaklar, lekeler, yara izleri, saç tedavisi, akut ve kronik ağrı,
          spor yaralanmaları, çeşitli deri hastalıklarının tedavisinde
          kullanılır.
        </p>
        <p className="text-gray-900 w-full">
          Mezoterapi cilt altındaki kolajen ve elastin liflerini uyararak
          etkisini gösterir. Bu şekilde o bölgedeki kan dolaşımı, lenfatik
          dolaşım ve immün cevap düzenlenir.
        </p>
        <p className="text-gray-900 w-full">
          Mezoterapi ajanları mikroenjeksiyon yöntemi ile direkt problemli
          bölgeye verilir. Bu şekilde sistemik bir etki oluşturmaksızın problem
          çözülür ve tedaviden maksimum fayda sağlanır.
        </p>
      </div>
    </div>
  );
};

export default Mezoterapi;
