import React from "react";
import peeling from "../servicesphotos/peeling.jpeg";

const EnzimPeeling = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={peeling} alt="Enzim Peeling" />
        <p className="text-gray-900 w-full">
          Enzim peeling, cildi derinlemesine temizlemek ve üst tabakasını
          soyarak yenilemek için kullanılan bir medikal güzellik yöntemidir. Ölü
          hücrelerin bulunduğu cildin en üst katmanını soyarak yeni cilt
          tabakasının çıkmasını sağlar. Cilt lekeleri, derinin rengini oluşturan
          pigment miktarının aşırı artmasından kaynaklanır. Enzim peeling ise
          ciltte pigment oluşmasını sağlayan “tirozinaz” adlı enzimi baskılar.
          Bu sayede lekeli bölgelerde pigment oluşumu azalır ve cilt rengi
          açılır. Yaşlılık lekekeri, güneş lekesi, gebelik lekesi ve akne izleri
          enzim peeling ile tedavi edilebilir.Yüz, boyun, dekolte bölgesi, sırt,
          el üzeri ve vücudun leke bulunan tüm bölgelerinde uygulanabilir.
        </p>
      </div>
    </div>
  );
};

export default EnzimPeeling;
