import React from "react";
import altinigne from "../servicesphotos/altin-igne.jpeg"
const BoyunDekolte = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
       <img src={altinigne} alt="Altın İğne" />
        <p className="text-gray-900 w-full">
          Boyun, dekolte ve el gibi alanlar genellikle bakım olarak ihmal edilen
          alanlardır. Özellikle boyun bölgesinde yağ bezlerinin az olması ve
          cildin ince olması nedeniyle erken kırışmalar ortaya çıkabilir.
          Dekolte bölgesi ise güneşten en az yüz bölgesi kadar etkilenir. İhmal
          edilen bir başka bölge ise ellerdir. Eller güneşe ve diğer dış
          etkenlere direkt maruz kaldığı için çok hızlı kurur, lekelenir ve
          kırışmaktadır.Yaşlanma etkileri olarak yorumlayabileceğimiz bu
          etkiler, sarkma kırışıklık ve cilt tonunda eşitsizlik olarak karşımıza
          çıkabilir.
        </p>
        <p className="text-gray-900 w-full font-bold">
          Boyun ve Dekolte-El Nasıl Gençleştirilir?
        </p>
        <p className="text-gray-900 w-full">
          Boyun dekolte - el bölgesi gençleştirme işlemi için birçok yöntem
          kullanılabilir. Bu yöntemler arasında;
        </p>
        <p className="text-gray-900 w-full">Boyun için uygulanacak dolgular</p>
        <p className="text-gray-900 w-full">Kolajen uygulaması</p>
        <p className="text-gray-900 w-full">
          Somon DNA yöntemi ile cildi gençleştirme
        </p>
        <p className="text-gray-900 w-full">Gençlik aşısı</p>
        <p className="text-gray-900 w-full">Altın iğne tedavisi</p>
        <p className="text-gray-900 w-full">
          Bu yöntemler hem el hem boyun dekolte hem de el bölgesinde başarılı
          bir şekilde sonuç veren yöntemlerdir.{" "}
        </p>
      </div>
    </div>
  );
};

export default BoyunDekolte;
