import React from 'react'

const Brands = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
    <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
    <header className="text-gray-900 w-full font-bold">
        Brands 
      </header>
      <p className="text-gray-900 w-full">
        -Novuma(Kalsiyum Hidroksilopatit Dolgu)
      </p>
      <p className="text-gray-900 w-full">
        -Innea Aqua 
      </p>
      <p className="text-gray-900 w-full">
        -Juwederm
      </p>
      <p className="text-gray-900 w-full">
        -Hyaldew(Tasarım Dolgu)
      </p>
      <p className="text-gray-900 w-full">
        -PB Serum (Enzim Tedavisi)
      </p>
      <p className="text-gray-900 w-full">
        -Paris Işıltısı(NCTF135)
      </p>
      <p className="text-gray-900 w-full">
        -H100 Gençlik Aşısı
      </p>
     
    </div>
  </div>
  )
}

export default Brands