import React from 'react'
import dolgu from "../servicesphotos/dolgu.jpeg"
import dolgu2 from "../servicesphotos/dudakdolgusu.jpeg"
const DolguUygulamalari = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
    <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
      <img  src={dolgu} alt="Dolgu Uygulamaları" />
      <p className="text-gray-900 w-full">
      Yaş almayla beraber erkek kadın farketmeksizin  yüz bölgesinde hacim kaybı başlar. Eğer hacim kaybına dolgu uygulamaları ile erkenden müdahale edilirse yüzde oluşacak kalıcı kırışıklıkların önüne geçilebilir. Aynı zamanda yüzün yukarı volümü desteklenerek yüzün sarkmasıda engellenir.,
      </p>
      <p className="text-gray-900 w-full">
      Dolgu yapılmasının birçok sebebi vardır bazen hacim kaybı görülen bölgelerde eski hacmi yeniden sağlamak için veya daha da fazla dolgunluk sağlamak için kullanılırken , bazen de çöküntünün cilt sarkıklığına sebep olduğu bölgelerde , çöken kısmı doldurmak ,cilt sarkması olan kısmı daha yukarıya taşımak için kullanır. Dolgu uygulama sonrasında daha belirgin daha dolgun hatlara sahip olunur ve sizi daha genç bir görünüme sahip olmanızı sağlar.
      </p>
      <img  src={dolgu2} alt="Dudak Dolgusu" />
      <header className="text-gray-900 w-full font-bold">
      Yüz Bölgesine Yapılan Dolgu İşlemleri Nelerdir?
      </header>
      <p className="text-gray-900 w-full ">
      Elmacık kemiklerini daha belirginleştirmek için Elmacık dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Çöken yanakların hacmini geri vermek için Yanak dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Dudakları daha dolgun ve hatlarını daha belirgin yapmak için Dudak dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Geride olan bir çeneyi öne almak için Çene dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      jawline hattını  belirginleştirmek amaçlı Jawline dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Geride olan bir alını daha bombeli hale getirmek için Alın dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Çökmüş olan şakakları doldurmak için Temporal dolgu
      </p>
      <p className="text-gray-900 w-full ">
      Burun ucunu kaldırmak ve kemerli burun görüntüsünü azaltmak için Burun dolgusu
      </p>
      <p className="text-gray-900 w-full ">
      Gözaltı çöküklüğü ve morarıklık için Göz altı ışık dolgusu
      </p>
    </div>
  </div>
  )
}

export default DolguUygulamalari