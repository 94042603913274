import React from "react";
import botox from "../servicesphotos/botox.jpeg";
import botox2 from "../servicesphotos/botox2.jpeg";
const BotoxUygulamasi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={botox} alt="Botox Uygulaması" />
        <p className="text-gray-900 w-full">
          Botox (Botulinum toksini), Clostridium botulinum adlı bakteriden elde
          edilen bir toksindir. Sinir uçlarında iletimi sağlayan maddelerin
          salınımını engelleyip, sinirler ile sinirlerin ulaştığı kaslar
          arasındaki iletimi durdurarak etkisini gösterir. Sinir iletiminin
          durması, sinirin ulaştığı kasın işlevlerinin azalmasını ya da tamamen
          kaybolmasını sağlar. Mimik kaslarının yıllar boyunca çalışması,
          üzerini örten deri üzerindeki kıvrımları belirgin hale getirir ve
          böylece yüzdeki dinamik çizgilenmeler ortaya çıkar. En sık ortaya
          çıkan dinamik çizgiler, alın, kaşlar arası, göz kenarları ve dudak
          çevresinde görülür. Alın ve göz kenarlarındaki çizgiler kişiye daha
          yaşlı bir görünüm, kaşlar arasındaki çizgiler ise kişiye çatık kaşlı,
          kızgın bir bakış ifadesi verir. Mimik kaslarına botox uygulanarak bu
          kasların hareketleri zayıflatılabilir, kas hareketlerindeki azalma,
          üzerindeki derinin, kas hareketleri ile katlanmasını ve katlanmaya
          bağlı çizgilenmeyi de azaltır. Bu şekilde yaşlı ve kızgın olarak
          görünen yüz ifadesinde de belirgin bir düzelme sağlanır.
        </p>
        <img src={botox2} alt="Botox Uygulaması" />
        <p className="text-gray-900 w-full">
          Estetik ve kozmetik amaçlarla ve tedavi amaçıyla aşırı terleyen
          bölgelerdeki(el,ayak,koltukaltı) terlemeyi azaltmak için Terleme
          botoxu ve kronik migren tedavisinde Migren botoxu ,diş sıkma
          probleminde ve yüz bölgesini inceltmek amacıyla Masseter botox
          uygulaması yapılır.
        </p>
      </div>
    </div>
  );
};

export default BotoxUygulamasi;
