import React from "react";
import epilasyon from "../servicesphotos/epilasyon.jpeg";
const LazerEpilasyon = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img className="h-96" src={epilasyon} alt="Lazer Epilasyon" />
        <p className="text-gray-900 w-full">
          İstenmeyen tüyler, birçok insan için hem konforsuz hem de can sıkıcı
          olabilir. Lazer epilasyon, tam da bu noktada mükemmel bir çözümdür.
          Kadın ya da erkek fark etmeksizin istenmeyen tüylerin kalıcı olarak
          yok edilmesini sağlar,klasik yöntemlere kıyasla acısızdır. Cilde zarar
          vermeden kıl köküne ulaşan lazer ışını, kökteki pigment yapısına
          inerek kıl kökünü hedef alır.Kıl kökünü kalıcı bir şekilde ortadan
          kaldırma işlemine lazer epilasyon denilmektedir. Lazer epilasyonda
          temel amaç, tüyü değil, kıl kökünü yok etmektir.Diğer epilasyon
          çeşitlerinden farklı olarak kalıcı ve sağlıklı olduğu için tercih
          sebebidir.
        </p>
      </div>
    </div>
  );
};

export default LazerEpilasyon;
