import React from "react";
import sacmezoterapisi from "../servicesphotos/sac-mezoterapisi.jpeg";
const SacMezoterapisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={sacmezoterapisi} alt="Saç Mezoterapisi" />
        <p className="text-gray-900 w-full">
          Saça Mezoterapisi, kadın ve erkeklere uygulanabilen, saç dökülmesi
          tedavisinde etkili bir yöntemdir. Küçük, ince uçlu iğnelerle deriye
          ufak dozlarda enjeksiyonlar yapılır. Saç kökleri için uyarıcı ve
          besleyici olan, saçların uzaması ve gelişmesine katkı sağlayan vitamin
          ve mineraller saçlı deri içerisine doğrudan verilir.Bu yöntemler
          sayesinde saçlarınızın dökülmesi azalır,yeni saçlar çıkar,daha
          sağlıklı ve dolgun saçlara sahip olabilirsiniz.
        </p>
      </div>
    </div>
  );
};

export default SacMezoterapisi;
