import React from "react";
import ozon from "../servicesphotos/ozonterapi.jpeg";
const OzonTerapi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={ozon} alt="Ozon Terapi" />
        <p className="text-gray-900 w-full">
          Atmosferin kaynaklarından biri olan ozon, oksijenin yüksek enerjili
          halidir. Her gün baktığımız mavi gökyüzünün kaynağı niteliğindeki
          ozon, dünyadaki yaşam için oldukça önemlidir. Dünya için bu kadar
          önemli nitelikteki ozon, tıp dünyasında günden güne daha çok
          araştırılmakta ve giderek daha önemli bir yere sahip olmaktadır.
        </p>
        <p className="text-gray-900 w-full">
          Ozonun temel iki tane özelliği bulunmaktadır;
        </p>
        <p className="text-gray-900 w-full">Ozon, iyi bir antioksidandır.</p>
        <p className="text-gray-900 w-full">
          Ozon, etkili nitelikte bir yüzey dezenfektanıdır. Mantar ve virüsler,
          ozon ile karşılaştıkları zaman canlılıklarını kaybetmektedirler.
        </p>
        <header className="text-gray-900 w-full font-bold">
          Minör Ozon Tedavisi
        </header>

        <p className="text-gray-900 w-full">
          Minör ozon tedavisinde, vücudun ihtiyacına göre belirlenen miktarda
          kan ozonlandıktan sonra, kas içerisine enjekte edilmektedir.
        </p>
        <header className="text-gray-900 w-full font-bold">
          Majör Ozon Tedavisi
        </header>

        <p className="text-gray-900 w-full">
          Majör ozon tedavisi:Hastadan 50-100 cc damardan alınan kanın sitratlı
          özel şiseler içerisine ozonlanarak, tekrar dolaşıma verilmesi
          işlemidir. Yaklaşık olarak 15-20 dakika süren bu uygulamalar ile,
          kanın akciğerden daha fazla oksijen alması sağlanarak, dokulara
          bırakması sağlanmaktadır.
        </p>
        <header className="text-gray-900 w-full font-bold">
          Rektal Ozon Tedavisi
        </header>

        <p className="text-gray-900 w-full">
          Rektal Ozon tedavisinde ozon gazının rektal yoldan bağırsaklara
          verilmesiyle gerçekleştirilen uygulamalardır.
        </p>
        <header className="text-gray-900 w-full">
          OZONTERAPİ HANGİ HASTALIKLARIN TEDAVİSİNDE KULLANILIR?
        </header>
        <p className="text-gray-900 w-full">-Bel ve Boyun Fıtığı</p>
        <p className="text-gray-900 w-full">-İskemik Kalp Hastalıkları</p>
        <p className="text-gray-900 w-full">-Kanser</p>
        <p className="text-gray-900 w-full">-Migren</p>
        <p className="text-gray-900 w-full">-Kolestrol Yüksekliği</p>
        <p className="text-gray-900 w-full">
          -Yaralar,Cilt Lezyonları,Yanıklar
        </p>
        <p className="text-gray-900 w-full">-Zona</p>
        <p className="text-gray-900 w-full">-Romatizmal Hastalıklar</p>
        <p className="text-gray-900 w-full">-Dolaşım Bozukluğu</p>
        <p className="text-gray-900 w-full">-Viral Hastalıklar</p>
        <p className="text-gray-900 w-full">-DM-HT</p>
        <p className="text-gray-900 w-full">-Diyabet Ayak</p>
        <p className="text-gray-900 w-full">
          -Geriatrik Bozukluklar(Alzheimer,Parkinson,demans)
        </p>
        <p className="text-gray-900 w-full">
          -Solunum Sistemi Hastalıkları(astım,koah)
        </p>
        <p className="text-gray-900 w-full">-Kronik Yorgunluk</p>
        <p className="text-gray-900 w-full">-Depresyon,Duygu-Durum Bozukluğu</p>
        <p className="text-gray-900 w-full">-Otoimmün Hastalıklar</p>
        <p className="text-gray-900 w-full">-Cilt Gençleştirme</p>
        <header className="text-gray-900 w-full font-bold">
          Torbalama Ozon Tedavisi
        </header>

        <p className="text-gray-900 w-full">
          Ozon torbalama uygulaması, yapılan değerlendirmeler doğrultusunda
          kişide bulunan iyileşmeyen yara bölgelerine ve yanık durumlarında
          bölgesel olarak problemli alana ozon gazının uygulanması işlemidir.
        </p>
        <p className="text-gray-900 w-full">
          Ozon torbalama, enfeksiyon odaklarını ortadan kaldırmaktadır.
        </p>
        <p className="text-gray-900 w-full">
          Yaranı ya da hasarlı dokunun iyileşmesini hızlandırmaktadır.
        </p>
        <p className="text-gray-900 w-full">
          Ozon torbalama, özellikle dolaşım problemi bulunan hastalarda majör
          terapi uygulamasıyla birlikte gerçekleştirildiği zaman, oldukça etkili
          ve başarılı sonuçlar ortaya çıkmaktadır.
        </p>
      </div>
    </div>
  );
};

export default OzonTerapi;
