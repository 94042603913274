import React from "react";
import migren from "../servicesphotos/botox3.jpeg"
const MigrenBotox = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={migren} alt="Migren Botox" />
        <p className="text-gray-900 w-full">
          Migren Botoxu uygulaması ile migrenin süresini, şiddetini ve sıklığını
          azaltır. Böylece hastalar 6 ay süreyle migren ağrılarından kurtulurlar
          ve yaşam kaliteleri de yükselmiş olur.
        </p>
      </div>
    </div>
  );
};

export default MigrenBotox;
