import React from "react";
import prp from "../servicesphotos/prp.jpeg";
const Prp = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={prp} alt="PRP" />

        <p className="text-gray-900  w-full">
          PRP (Platelet Rich Plasma), platelet yönünden zenginleştirilmiş plazma
          uygulamasına verilen yöntemin kısaltılmış ismidir. Bu yöntemde
          uygulamanın yapılacağı kişiden kan alınır, steril ortamda santrifüj
          edilerek trombositten zengin plazma elde edilir ve enjeksiyon yöntemi
          ile işlem yapılacak yere uygulanır.
        </p>
        <p className="text-gray-900  w-full ">
          Anti-aging ve Cilt problemlerinde; PRP tedavisi kırışıklık, leke
          oluşumları, sivilce ve yara izleri ve cilt çatlaklarının tedavisinde
          kullanılmaktadır. Uygulama sonrasında sarkmada ve kırışıklık izlerinde
          azalma gözlenir.
        </p>
        <p className="text-gray-900  w-full">
          PRP, iyileşmeyen yaralarda, ortopedik sorunlarda, vasküler
          problemlerde de tedavi amaçlı uygulanır.
        </p>
        <p className="text-gray-900  w-full">
          Lipoliz yağ hücrelerinin küçültülmesi veya parçalanması anlamına
          gelmektedir. Lipoliz mezoterapisi sınırlı bölgelerde (lokal) aşırı yağ
          birikimi tedavisinde sık tercih edilen bir mezoterapi yöntemidir.
        </p>
      </div>
    </div>
  );
};

export default Prp;
