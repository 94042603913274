import React from "react";
import "tw-elements";
import photo4 from "../photos/photo4.jpeg";
import tulayyildirim1 from "../photos/tulayyildirim1.jpeg";
import tulayyildirim3 from "../photos/tulayyildirim3.jpeg"

const About = () => {
  return (
    <div className="flex flex-col my-12 mx-6 ">
      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row space-x-10 items-center justify-center">
        <div className="w-72 sm:w-96">
          <div
            id="carouselExampleIndicators"
            class="carousel slide relative"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner relative w-full overflow-hidden">
              <div class="carousel-item active float-left w-full">
                <img
                  src={tulayyildirim1}
                  class="block w-full h-[456px] object-contain"
                  alt="DR. TÜLAY YILDIRIM"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src={photo4}
                  class="block w-full h-[456px] object-contain"
                  alt="DR. TÜLAY YILDIRIM"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src={tulayyildirim3}
                  class="block w-full h-[456px] object-contain"
                  alt="DR. TÜLAY YILDIRIM"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon inline-block bg-no-repeat"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon inline-block bg-no-repeat"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="flex flex-col space-y-6 w-2/3">
          <header className="font-bold text-xl text-gray-900">
            DR.TÜLAY YILDIRIM
          </header>
          <header className="font-bold text-lg text-gray-900">
            Medikal Estetik
          </header>
          <p className="text-gray-900">
            1995 yılında Anadolu(Osmangazi) üniversitesi Tıp fakültesinden mezun
            oldum.2004 yılında Medikal Estetik Hekimliği eğitim ve sertifikasını
            aldıktan sonra Flavius Güzellik Merkezi doktoru olarak 5 yıl
            çalıştım.Daha sonra İzmir Essi Güzellik Merkezi şubelerinin doktoru
            olarak çalıştım.
          </p>
          <p className="text-gray-900">
            Şu anda 26 yıllık hekimim ve kendi muayenehanemde
            <text className="font-bold"> hizmet vermekteyim.</text>
          </p>
          <p className="font-bold  text-right text-gray-900">
            Sevgi ile kalın ..
          </p>
          <p></p>
          <blockquote class="italic  font-semibold text-gray-900 space-y-4">
            <p>
              " Küçük dokunuşlarla daha dinlenmiş,daha sağlıklı bir görünüm
              için,kendimize olan güvenimizin artması,daha güzel yaş almak için
              güvenilir bir adres arıyorsanız Kliniğimize bekleriz.."
            </p>
            <p className="text-right ">
              "Hayatınıza ve imajınıza dokunmaya devam ediyoruz.."
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default About;
