import React from "react";
import terleme from "../servicesphotos/asiriterleme.jpg"
const AsiriTerlemeTedavisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img className="h-96" src={terleme} alt="Aşırı Terleme Tedavisi" />
        <p className="text-gray-900 w-full">
          Botox, ter bezlerine uygulandığında, ter bezleri ile sinir uçları
          arasındaki iletim de durdurularak ter bezlerinin çalışması
          azaltılabilir. Vücudun en çok terleyen bölgeleri, avuç
          içleri,ayaklar,koltuk altı bölgesidir. Aşırı terleme ve buna bağlı ter
          kokusu şikayeti olan kişilerin terleyen bölgelerine botox
          uygulandığında şikayetlerinde düzelme sağlanır .
        </p>
       
      </div>
    </div>
  );
};

export default AsiriTerlemeTedavisi;
