import React from "react";
import glutatyon from "../servicesphotos/glutatyon.jpeg"
const GlutatyonCTedavisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={glutatyon} alt="Glutatyon C Tedavisi" />
        <p className="text-gray-900 w-full">
          Antioksidan etkilidir. Metabolik olaylar, maruz kaldığınız radyasyon ,
          daha birçok zararlı etken vücudunuzda hücrelere zarar veren serbest
          oksijen radikali adı verilen moleküller oluşturur. Bu moleküller aşırı
          miktarda artarsa vücudunuzun doğal antioksidan mekanizması onları
          etkisiz hale getiremez. Sonuç olarak hücreleriniz zarar görür ve
          birçok hastalığın riski artar.
        </p>
        <p className="text-gray-900 w-full">
          Bu risklerin çözümünden biri ise vücutta bulunan doğal glutatyon
          seviyesini arttırmak veya damar yolu ile takviye etmek. Bu konuda
          glutatyon o kadar önemlidir ki hücre içi glutatyon seviyesi yaşam
          süresinin bir belirtecidir.
        </p>
        <p className="text-gray-900 w-full">
          Glutatyon Tedavisi uygulamasında hastaya damar yolu açılır ve damar
          yolu ile 20 dk gibi kısa bir sürede uygulanır.
        </p>
      </div>
    </div>
  );
};

export default GlutatyonCTedavisi;
