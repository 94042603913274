import React from "react";
import peeling from "../servicesphotos/peeling2.jpeg"
const KimyasalPeeling = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={peeling} alt="Kimyasal Peeling" />
        <p className="text-gray-900 w-full">
          Kimyasal Peeling vücudun çeşitli bölgelerine uygulanabilen ürün
          çeşitleridir. Cildin görünümünü veya hissini iyileştirmek için
          kullanılır. Bu prosedür sırasında, cildin pul pul dökülmesine ve
          sonunda soyulmasına neden olan yöntem çeşitli kimyasal peeling
          ürünleri ile yapılmaktadır. Bu ürün uygulandığında, altındaki yeni
          cilt pürüzsüz hale gelir.
        </p>
        <p className="text-gray-900 w-full">
          Güneş, yanık ve herediter faktörlerin deride oluşturduğu ince
          kırışıklar, güneşe ve yaşa bağlı lekeler, çiller, yüzdeki koyu renkli
          lekeler, melazma denilen hormonal veya gebelik lekeleri peeling ile
          düzeltilebilir; hatta iyileştirilebilir Aktif aknelerde iyileşmeye ve
          akne izlerinde düzelmeye yardımcıdır. Ciltte yağlanma ve gözenekleri
          azalma üzerine de olumlu etkisi vardır. Ciltteki kollajen dokusu ve
          elastin liflerdeki bozulmalar sonucu meydana gelen ince çizgiler ve
          kırışıklıklara karşı etkilidir. Deride oluşturulan soyulmanın ardından
          yeni gelen cildin dokusu daha genç, parlak, pürüzsüz ve lekesizdir.
        </p>
      </div>
    </div>
  );
};

export default KimyasalPeeling;
