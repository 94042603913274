import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Mezoterapi from "./services/anti-aging/Mezoterapi";
import Lipoliz from "./services/anti-aging/Lipoliz";
import Prp from "./services/anti-aging/Prp";
import GenclikAsisi from "./services/anti-aging/GenclikAsisi";
import BoyunDekolte from "./services/anti-aging/BoyunDekolte";
import GozAltiMezoterapisi from "./services/anti-aging/GozAltiMezoterapisi";
import IpAskiEstetigi from "./services/yuz-mimarisi/IpAskiEstetigi";
import AltinIgneTedavisi from "./services/yuz-mimarisi/AltinIgneTedavisi";
import DolguUygulamalari from "./services/yuz-mimarisi/DolguUygulamalari";
import BotoxUygulamasi from "./services/yuz-mimarisi/BotoxUygulamasi";
import AsiriTerlemeTedavisi from "./services/yuz-mimarisi/AsiriTerlemeTedavisi";
import MasseterBotox from "./services/yuz-mimarisi/MasseterBotox";
import MigrenBotox from "./services/yuz-mimarisi/MigrenBotox";
import OzonTerapi from "./services/vucut-tedavileri/OzonTerapi";
import GlutatyonCTedavisi from "./services/vucut-tedavileri/GlutatyonCTedavisi";
import LekeTedavileri from "./services/cilt-problemleri/LekeTedavileri";
import KimyasalPeeling from "./services/cilt-bakim-yontemleri/KimyasalPeeling";
import EnzimPeeling from "./services/cilt-bakim-yontemleri/EnzimPeeling";
import Hydrafacial from "./services/cilt-bakim-yontemleri/Hydrafacial";
import SacPRP from "./services/sac-tedavileri/SacPRP";
import SacMezoterapisi from "./services/sac-tedavileri/SacMezoterapisi";
import LazerEpilasyon from "./services/lazer-epilasyon/LazerEpilasyon";
import Brands from "./services/brands/Brands";

const App = () => {
  return (
    <div className="flex flex-col h-auto bg-[#F5F5F5] ">
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/hakkimizda" element={<About />} />
        <Route exact path="/iletisim" element={<Contact />} />
        {/* ANTI-AGING */}
        <Route exact path="/mezoterapi" element={<Mezoterapi />} />
        <Route exact path="/gidi-lipoliz" element={<Lipoliz />} />
        <Route exact path="/prp" element={<Prp />} />
        <Route exact path="/genclik-asisi" element={<GenclikAsisi />} />
        <Route
          exact
          path="/boyun-dekolte-el-genclestirme-tedavileri"
          element={<BoyunDekolte />}
        />
        <Route
          exact
          path="/goz-alti-mezoterapisi"
          element={<GozAltiMezoterapisi />}
        />
        {/* YÜZ MİMARİSİ */}
        <Route exact path="/ip-aski-estetigi" element={<IpAskiEstetigi />} />
        <Route
          exact
          path="/altin-igne-tedavisi"
          element={<AltinIgneTedavisi />}
        />
        <Route
          exact
          path="/dolgu-uygulamalari"
          element={<DolguUygulamalari />}
        />
        <Route exact path="/botox-uygulamasi" element={<BotoxUygulamasi />} />
        <Route
          exact
          path="/asiri-terleme-tedavisi"
          element={<AsiriTerlemeTedavisi />}
        />
        <Route exact path="/masseter-botox" element={<MasseterBotox />} />
        <Route exact path="/migren-botox" element={<MigrenBotox />} />
        {/*VÜCUT TEDAVİLERİ */}
        <Route exact path="/ozon-terapi" element={<OzonTerapi />} />
        <Route
          exact
          path="/glutatyon-c-tedavisi"
          element={<GlutatyonCTedavisi />}
        />
        {/* CİLT PROBLEMLERİ */}
        <Route
          exact
          path="/leke-tedavileri-ve-cilt-problemleri-tedavileri"
          element={<LekeTedavileri />}
        />
        {/* CİLT BAKIM YÖNTEMLERİ */}
        <Route exact path="/kimyasal-peeling" element={<KimyasalPeeling />} />
        <Route exact path="/enzim-peeling" element={<EnzimPeeling />} />
        <Route exact path="/hydrafacial" element={<Hydrafacial />} />
        {/* SAÇ TEDAVİLERİ */}
        <Route exact path="/sac-prp" element={<SacPRP />} />
        <Route exact path="/sac-mezoterapisi" element={<SacMezoterapisi />} />
        {/* LAZER EPİLASYON */}
        <Route exact path="/lazer-epilasyon" element={<LazerEpilasyon />} />
        {/* BRANDS */}
        <Route exact path="/brands" element={<Brands />} />
      </Routes>
    </div>
  );
};

export default App;
