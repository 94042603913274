import React from "react";
import gozalti from "../servicesphotos/gözaltiisikdolgusu.jpeg";
const GozAltiMezoterapisi = () => {
  return (
    <div className="flex h-auto w-full justify-center items-center py-6">
      <div className="w-2/3 my-6 space-y-6 justify-center  text-start  items-center flex flex-col">
        <img src={gozalti} alt="Mezoterapi" />
        <p className="text-gray-900 w-full">
          Göz altı mezoterapisi, hyaluronik asit, antioksidanlar, aminoasitler
          ve B grubu vitaminlerden oluşan kokteyl karışımıdır.Mezoterapinin
          içerisindeki bu farklı maddeler birbirleriyle iyileştirici etki
          oluşturarak göz çevresinde yaşanan kırışıklık, morarma, sarkma ve renk
          farklılıkları sorununu giderebilmektedir.Bu uygulama sayesinde yorgun
          ve yaşlı görünüm yerini dinç canlı bir ifadeye bırakır.
        </p>
      </div>
    </div>
  );
};

export default GozAltiMezoterapisi;
